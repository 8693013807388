var set_col_hights = function (el, extra) {
  if (typeof extra === "undefined") {
    extra = 0;
  }

  if ($(el).css("min-height")) {
    TweenMax.set($(el), {
      clearProps: "min-height"
    });
  }

  // Get an array of all element heights
  var elementHeights = $(el).map(function () {
    return $(this).height();
  }).get();
  //console.log(elementHeights);
  // Math.max takes a variable number of arguments
  // `apply` is equivalent to passing each height as an argument
  var maxHeight = Math.max.apply(null, elementHeights);

  // Set each height to the max height
  $(el).css("min-height", maxHeight + extra + "px");
};

// how to use...

// $(window).load(function() {
//   if (Modernizr.mq('(min-width: 767px)')) {
//     set_col_hights('.feature_box .body');
//     set_col_hights('.home .output_review .comment');
//     set_col_hights('.blog_feed_heights');
//   }
// });

var do_these_heights = function () {
  if (Modernizr.mq('(min-width: 767px)')) {
    if ($(window).width() > 767) {
      set_col_hights($(".sub_service_block"));
    }
    if ($(window).width() > 991) {
      set_col_hights($(".job_title"));
      set_col_hights($(".page-template-page-case-studies .second_section .title_wrap p"));
      set_col_hights($(".list_content"));
      set_col_hights($(".blog_title a"));
      set_col_hights($(".excerpt p"));
      set_col_hights($(".blog_posts"));
      set_col_hights($(".case_study_block h3"));
      set_col_hights($(".why_choose_inner"));
      set_col_hights($(".cta_box_move_sub .cta_title"));
       set_col_hights($(".title_wrap_inner h3")); 
    }
    // Fix for empty elements getting a height set on them
    $("p, h1, h2, h3, h4").each(function () {
      if ($(this).is(':empty')) {
        $(this).css("display", "none");
      }
    });
    $(".mini_grouped_slider .outer_image_wraps").each(function () {

      set_col_hights($(this).find(".business_desc_wrapper"));
    });
  }
};


// Get outerheight if you are using also padding.

function getBiggestOuterHeight(height_to_compare) {

  var this_outerheight;
  var array_of_heights = [];

  $(height_to_compare).each(function () {
    this_outerheight = $(this).outerHeight(true);
    //console.log(this_outerheight);
    array_of_heights.push(this_outerheight);
  });

  var largest_height = Math.max.apply(null, array_of_heights);
  //console.log(largest_height);

  TweenMax.set($(height_to_compare), {
    minHeight: largest_height
  });
}

$(window).load(function () {
  do_these_heights();
  getBiggestOuterHeight($(".pack_wrap .packages_descripstion"));
});

$(window).resize(function () {
  do_these_heights();
});
