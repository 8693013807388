// Home Page Slider

$("#SURGE_GSAP_slider1 .SURGE_gsap_inner>div, .normal_banner:nth-child(2) .banner_btn").addClass("first_button");
$("#SURGE_GSAP_slider1 .SURGE_gsap_inner>div, .normal_banner:nth-child(3) .banner_btn").addClass("second_button");
$("#SURGE_GSAP_slider1 .SURGE_gsap_inner>div, .normal_banner:nth-child(4) .banner_btn").addClass("third_button");
$("#SURGE_GSAP_slider1 .SURGE_gsap_inner>div, .normal_banner:nth-child(5) .banner_btn").addClass("fourth_button");


// Add-on function for Owl Carousel. Adds class to first and last slides.
// function checkClasses() {
//   var total = $('.owl-carousel .owl-stage .owl-item.active').length;
//   $('.owl-carousel .owl-stage .owl-item').removeClass('firstActiveItem lastActiveItem');
//   $('.owl-carousel .owl-stage .owl-item.active').each(function (index) {
//     if (index === 0) {
//       // this is the first one
//       $(this).addClass('firstActiveItem');
//     }
//     if (index === total - 1 && total > 1) {
//       // this is the last one
//       $(this).addClass('lastActiveItem');
//     }
//   });
// }

$(document).ready(function () {
  var carousel = $(".fourth_section .owl-carousel");
  carousel.owlCarousel({
    loop: true,
    nav: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1
      },
      1024: {
        items: 1 
      }
    },
    navText: [$('.fourth_section .owl_prev_custom'), $('.fourth_section .owl_next_custom')]
  });
});

$(document).ready(function () {
  var carousel = $(".new_case .owl-carousel");
  carousel.owlCarousel({
    loop: true,
    nav: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1
      },
      1024: {
        items: 1 
      }
    },
    navText: [$('.new_case .owl_prev_custom'), $('.new_case .owl_next_custom')]
  });
});




// Home Page Hovers 

$('.cta_box').hover(function () {
  TweenMax.to($(".cta_box_move", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    y: '0'
  });
  TweenMax.to($(".cta_box_move_sub", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    y: '0'
  });
  TweenMax.to($(".cover_1", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(61,184,69, 1.0);'
  });
  TweenMax.to($(".cover_2", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(42, 18, 143, 1.0);'
  });
  TweenMax.to($(".cover_3", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(255, 106, 21, 1.0);'
  });
  TweenMax.to($(".cover_count_sixth_1", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(63, 197, 255, 1.0);'
  });
  TweenMax.to($(".cover_count_sixth_2", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(242, 40, 38, 1.0);'
  });
  TweenMax.to($(".cover_count_sixth_3", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(255, 194, 57, 1.0);'
  });
}, function () { // This acts like a roll off state.
  TweenMax.to($(".cta_box_move", this), 0.5, { // Add tweenmax. 
    ease: Power3.easeInOut,
    y: '364'
  });
  TweenMax.to($(".cta_box_move_sub", this), 0.5, { // Add tweenmax. 
    ease: Power3.easeInOut, 
    y: '254' 
  });
  TweenMax.to($(".cover_1", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(61,184,69,.6);'
  });
  TweenMax.to($(".cover_2", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(42, 18, 143, 0.6);'
  });
  TweenMax.to($(".cover_3", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(255, 106, 21, 0.6);'
  });
  TweenMax.to($(".cover_count_sixth_1", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(63, 197, 255, 0.5);'
  });
  TweenMax.to($(".cover_count_sixth_2", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(242, 40, 38, 0.5);'
  });
  TweenMax.to($(".cover_count_sixth_3", this), 0.5, { // Add tweenmax.
    ease: Power3.easeInOut,
    backgroundColor: 'rgba(255, 194, 57, 0.5);'
  });
});

if ($(window).width() > 767) {
  $('.why_choose_inner').hover(function () {
    TweenMax.to($(".why_content", this), 0.5, { // Add tweenmax.
      ease: Power4.easeInOut,
      display: 'block',
      opacity: '1.0',
      y: '20px'
    });
  }, function () { // This acts like a roll off state.
    TweenMax.to($(".why_content", this), 0.5, { // Add tweenmax. 
      ease: Power4.easeInOut,
      display: 'none',
      opacity: '0',
      y: '0px'
    });
  });
}

// About Page Hovers 

// $('.about_hover_link').hover(function() {
//   TweenMax.to($(".cover_about", this), 0.5, { // Add tweenmax.
//     opacity: '0',
//     ease: Power2.easeInOut,
//     top: '260',
//     scaleY: 0,
//     transformOrigin: "bottom top"
//   });
//   TweenMax.to($(".about_cover_fill", this), 0.5, { // Add tweenmax.
//     opacity: '1',
//     ease: Power1.easeInOut,
//     delay: 0.5
//   });
// }, function() { // This acts like a roll off state.
//   TweenMax.to($(".cover_about", this), 0.5, { // Add tweenmax.
//     opacity: '1',
//     ease: Power2.easeInOut,
//     top: '0',
//     scaleY: 1,
//     transformOrigin: "bottom top"
//   });
//   TweenMax.to($(".about_cover_fill", this), 0.5, { // Add tweenmax.
//     opacity: '0',
//     ease: Power1.easeInOut,
//     delay: 0.5
//   });
// });




$('.SURGE_slide_buttons').hover(function () {
  TweenMax.to($(".arrow-1", this), 0.5, { // Add tweenmax.
    fill: '#007246',
    ease: Power4.easeInOut,
  });
  TweenMax.to($(".arrow-2", this), 0.5, { // Add tweenmax.
    stroke: '#8dc63f',
    ease: Power4.easeInOut,
  });
}, function () { // This acts like a roll off state.
  TweenMax.to($(".arrow-1", this), 0.5, { // Add tweenmax.
    fill: '#8dc63f',
    ease: Power4.easeInOut,
  });
  TweenMax.to($(".arrow-2", this), 0.5, { // Add tweenmax.
    stroke: '#fff',
    ease: Power4.easeInOut,
  });
});




// Side Menu 

var menuLeft = document.getElementById('cbp-spmenu-s1'),
  menuRight = document.getElementById('cbp-spmenu-s2'),
  menuTop = document.getElementById('cbp-spmenu-s3'),
  menuBottom = document.getElementById('cbp-spmenu-s4'),
  showLeft = document.getElementById('showLeft'),
  showRight = document.getElementById('showRight'),
  showTop = document.getElementById('showTop'),
  showBottom = document.getElementById('showBottom'),
  showLeftPush = document.getElementById('showLeftPush'),
  showRightPush = document.getElementById('showRightPush'),
  body = document.body;

// showLeft.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuLeft, 'cbp-spmenu-open' );
//   disableOther( 'showLeft' );
// };
// showRight.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuRight, 'cbp-spmenu-open' );
//   disableOther( 'showRight' );
// };
// showTop.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuTop, 'cbp-spmenu-open' );
//   disableOther( 'showTop' );
// };
// showBottom.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuBottom, 'cbp-spmenu-open' );
//   disableOther( 'showBottom' );
// };
showLeftPush.onclick = function () {
  classie.toggle(this, 'active');
  classie.toggle(body, 'cbp-spmenu-push-toright');
  classie.toggle(menuLeft, 'cbp-spmenu-open');
  // disableOther( 'showLeftPush' );
};
// showRightPush.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( body, 'cbp-spmenu-push-toleft' );
//   classie.toggle( menuRight, 'cbp-spmenu-open' );
//   disableOther( 'showRightPush' );
// };

// function disableOther( button ) {
//   if( button !== 'showLeft' ) {
//     classie.toggle( showLeft, 'disabled' );
//   }
//   if( button !== 'showRight' ) {
//     classie.toggle( showRight, 'disabled' );
//   }
//   if( button !== 'showTop' ) {
//     classie.toggle( showTop, 'disabled' );
//   }
//   if( button !== 'showBottom' ) {
//     classie.toggle( showBottom, 'disabled' );
//   }
//   if( button !== 'showLeftPush' ) {
//     classie.toggle( showLeftPush, 'disabled' );
//   }
//   if( button !== 'showRightPush' ) {
//     classie.toggle( showRightPush, 'disabled' );
//   }
// }


// Nav Menu Burger Menu

$('.nav-icon1').click(function () {
  $('.main_nav').toggleClass('menuOpen');
  $(this).toggleClass('open');
});

// Nav Drop Down

$('li.is_dropdown.parent_li').hover(function () {
  $(".mega_dropdown").css("opacity", "0");
  $(this).find(".mega_dropdown").css("opacity", "1");
  var tl = new TimelineMax();
  TweenLite.killTweensOf(".mega_dropdown > li");
  TweenLite.set(".mega_dropdown > li", {
    clearProps: "all"
  });
  // tl.from('.mega_dropdown', .25, {
  //   ease: Power2.easeOut,
  //   transformOrigin: "top",
  //   rotationY: 90
  // })
  tl.staggerFrom('.mega_dropdown > li', .2, {
    ease: Power1.easeIn,
    y: -20,
    opacity: 0
  }, .015);
  // tl.staggerFrom('.mega_dropdown > li a', .2, {ease: Power1.easeIn, opacity: 0, x: -60}, .01); 
}, function () {
  $(".mega_dropdown").css("opacity", "0");
});




// Social Buttons 

$('.facebook, .twitter').hover(function () {
  TweenMax.to($(".cls-1", this), 0.5, { // Add tweenmax.
    fill: '#007246',
    ease: Quad.easeInOut
  });
}, function () { // This acts like a roll off state.
  TweenMax.to($(".cls-1", this), 0.5, { // Add tweenmax.
    fill: '#8dc63f',
    ease: Quad.easeInOut
  });
});



$('.open_nav').hover(function () {
  TweenMax.to($(this), 0.09, { // Add tweenmax.
    ease: Power1.easeInOut,
    scale: 0.9
  });
}, function () { // This acts like a roll off state.
  TweenMax.to($(this), 0.09, { // Add tweenmax.
    ease: Power1.easeInOut,
    scale: 1
  });
});




// Scroll to top 

$(document).ready(function () {

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.scrollup').fadeIn();
    } else {
      $('.scrollup').fadeOut();
    }
  });

  $('.scrollup').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 600);
    return false;
  });

});



// Roll Overs For Mid CTA:

$('.case_wrapper_link').hover(function () {
  TweenMax.to($(this).find(".blue_button"), 0.5, { // Add tweenmax.
    backgroundColor: '#9EB3BE',
    ease: Power3.easeInOut
  });
}, function () { // This acts like a roll off state.
  TweenMax.to($(this).find(".blue_button"), 0.5, { // Add tweenmax.
    backgroundColor: '#4d95ba',
    ease: Power3.easeInOut
  });
});




// Blog expandable areas

$(".row_year").on("click", function () {
  var that = this;
  $(".years_posts").css("display", "none");
  $(".row_year .icon").css("transform", "none");
  $(that).next().css("display", "block");
  TweenMax.to($(that).find(".icon"), 0.4, { // Fill the middle
    ease: Power3.easeInOut,
    rotation: 180
  });
});

$(".row_cat").on("click", function () {
  var that = this;
  $(".recent_posts").css("display", "none");
  $(".row_cat .icon").css("transform", "none");
  $(that).next().css("display", "block");
  TweenMax.to($(that).find(".icon"), 0.4, { // Fill the middle
    ease: Power3.easeInOut,
    rotation: 180
  });
});

// End blog expandable areas


// Expandable Content. Add Display none on the Elements in the CSS.

$(".expand_button").on("click", function () {
  if ($(this).hasClass("open")) {
    TweenMax.set($(this).find("img"), 5, {
      rotation: "0",
      ease: Power4.easeInOut
    });
    TweenMax.set($(this).closest(".the_row").find(".hidden_content_wrapper"), {
      display: "none",
      padding: "0px"
    });
    $(this).removeClass("open");
  } else {
    goToByScroll($(this).parent().parent().parent(), 10, 0.5);
    TweenMax.set(".expandable_row", {
      // borderBottom: "2px solid #89C3C0"
    });
    TweenMax.set(".expand_button img", {
      rotation: "0"
    });
    TweenMax.set(".hidden_content_wrapper", {
      display: "none",
      padding: "0px"
    });
    $(".expand_button").removeClass("open");
    $(this).addClass("open");
    TweenMax.set($(this).parent().parent().parent(), {
      borderBottom: "none"
    });
    TweenMax.set($(this).find('img'), {
      rotation: "180",
      ease: Power4.easeInOut
    });
    TweenMax.set($(this).parent().parent().parent().next(), {
      display: "block",
      padding: "10px 0"
    });
  }
});

$(".expandable_row h3").on("click", function () {
  $(this).closest(".the_row").find(".expand_button").trigger("click");
});




//  Out Team Hide and Show a Repater with attr

$(".learmore_btn").on("click", function () {
  var class_to_show = $(this).attr("data-attr");
  $(".the_childs_journey").hide();
  $("." + class_to_show).show();
  // console.log(class_to_show);
  goToByScroll($("." + class_to_show), 0, 0.8);
});


$(document).on("click", ".close_btn", function () {
  $(this).parent().css("display", "none");
});



// $('#SURGE_GSAP_slider_journey').each(function(i) {

//  var thisID = $(this).attr('id');
//  console.log(thisID);

//  $(this).attr('id', thisID + '-' + i);
//  console.log(thisID);

// });

// Team Page Categories Show Hide


$('.team_slider_wrapper').hover(function () {
  TweenMax.to($(".team_desc_wrapper", this), 0.5, { // Add tweenmax.
    ease: Power4.easeInOut,
    autoAlpha: '0'
  });
}, function () { // This acts like a roll off state.
  TweenMax.to($(".team_desc_wrapper", this), 0.5, { // Add tweenmax. 
    ease: Power4.easeInOut,
    autoAlpha: '1.0'
  });
});



$(".team_slider_wrapper").on("click", function () {
  var that = this;
  $(".hidden_content_row").css("display", "none");
  $(this).closest(".row-centered").next().css("display", "block");
  goToByScroll($(this).closest(".row-centered").next(), 0, 0.8);
  // $( ".staff_description" ).clone().prependTo($(".hidden_content_row") );
  $(this).closest(".row-centered").next().html($(that).find(".staff_description").html());
  // console.log();
});



// Testimonails

$(".testimonials_slider_wrapper").on("click", function () {
  var that = this;
  $(".hidden_content_row").css("display", "none");
  $(this).closest(".row-centered").next().css("display", "block");
  goToByScroll($(this).closest(".row-centered").next(), 0, 0.8);
  // $( ".staff_description" ).clone().prependTo($(".hidden_content_row") );
  $(this).closest(".row-centered").next().html($(that).find(".staff_description").html());
  // console.log();
});


$(".gallery_section #Filters fieldset button:not('.show-all')").on("click", function () {
  $(".gallery_section #Filters fieldset .show-all").removeClass("all_active");
});

$(".gallery_section #Filters fieldset #Reset").on("click", function () {
  $(this).addClass("all_active");
});

// Blog section
$(".recent_post_btn").on("click", function () {
  $(".recent_wrap").css("display", "block");
  TweenMax.to($(".recent_wrap"), 0.3, {
    autoAlpha: 1,
    y: "0",
    ease: Power1.easeInOut
  });
  $(".archive_wrap").css("display", "none");
  TweenMax.to($(".archive_wrap"), 0.3, {
    autoAlpha: 0,
    y: "-20",
    ease: Power1.easeInOut
  });
  $(".categories_wrap").css("display", "none");
  TweenMax.to($(".categories_wrap"), 0.3, {
    autoAlpha: 0,
    y: "-20",
    ease: Power1.easeInOut
  });
});

$(".archive_btn").on("click", function () {
  $(".archive_wrap").css("display", "block");
  TweenMax.to($(".archive_wrap"), 0.3, {
    autoAlpha: 1,
    y: "0",
    ease: Power1.easeInOut
  });
  $(".recent_wrap").css("display", "none");
  TweenMax.to($(".recent_wrap"), 0.3, {
    autoAlpha: 0,
    y: "-20",
    ease: Power1.easeInOut
  });
  $(".categories_wrap").css("display", "none");
  TweenMax.to($(".categories_wrap"), 0.3, {
    autoAlpha: 0,
    y: "-20",
    ease: Power1.easeInOut
  });
});

$(".categories_btn").on("click", function () {
  $(".categories_wrap").css("display", "block");
  TweenMax.to($(".categories_wrap"), 0.3, {
    autoAlpha: 1,
    y: "0",
    ease: Power1.easeInOut
  });
  $(".archive_wrap").css("display", "none");
  TweenMax.to($(".archive_wrap"), 0.3, {
    autoAlpha: 0,
    y: "-20",
    ease: Power1.easeInOut
  });
  $(".recent_wrap").css("display", "none");
  TweenMax.to($(".recent_wrap"), 0.3, {
    autoAlpha: 0,
    y: "-20",
    ease: Power1.easeInOut
  });
});
